<template>
  <div class="FormMessage">
    <CardUser v-if="!isMultipleUsers" :doHideActions="true" :user="user" />
    <div v-else>
      <transition-group appear name="slide-down">
        <TagUser
          v-for="currentUser in usersToShow"
          :key="currentUser.id"
          :class="{ tagError: userIdsFailed.includes(currentUser.id) }"
          :user="currentUser"
          @remove="removeUser(currentUser.id)" />
      </transition-group>
      <div v-if="numberOfUsersHidden > 0">
        <Btn class="sm inv info" @click="doShowMore = !doShowMore">
          <Icon :name="doShowMore ? 'up' : 'down'" />
          Rodyti {{ doShowMore ? 'mažiau' : 'daugiau' }} {{ numberOfUsersHidden && `(${numberOfUsersHidden})` }}
        </Btn>
      </div>
    </div>
    <Field
      v-if="reservationsOptions.length"
      label="Užsakymas"
      :options="reservationsOptions"
      type="select"
      :value.sync="message.reservationId" />
    <Field
      data-cy="subject"
      label="Tema"
      :maxlength="255"
      required
      :value.sync="message.subject" />
    <Field
      data-cy="messageText"
      label="Tekstas"
      :maxlength="10000"
      required
      type="textarea"
      :value.sync="message.text" />
    <Field
      label="Automatiškai prikabinti „Mielas, Kliente!“ ir „Saulėtos dienos linki...“ dalis prie žinutės"
      type="checkbox"
      :value="!message.isRaw"
      @update:value="message.isRaw = !$event" />
  </div>
</template>

<script>
import { getUniqueArray, getters } from '../../utils'
import CardUser from '../admin/CardUser'
import TagUser from '../admin/TagUser'

export default {
  components: { TagUser, CardUser },
  props: {
    message: Object,
    userIdsFailed: Array,
  },
  data() {
    return {
      doShowMore: false,
    }
  },
  computed: {
    reservationsOptions() {
      const reservations = this.query('reservation', {
        userId: this.user.id,
      })

      if (!reservations.length) return reservations

      const projectIds = getUniqueArray(reservations.map(reservation => reservation.projectId))
      const projects = this.query('project', {
        id: {
          $in: projectIds,
        },
        $limit: projectIds.length,
      })

      return reservations
        .map(reservation => [
          reservation.id,
          projects.find(project => project.id === reservation.projectId).name,
        ])
    },
    user() {
      return this.query('user', this.message.userId)
    },
    users() {
      return this.query('user', { id: { $in: this.message.userId } })
    },
    usersToShow() {
      return this.users
        .slice(0, this.numberOfUsersToShow)
    },
    numberOfUsersToShow() {
      return this.doShowMore ? 1000 : 12
    },
    numberOfUsersHidden() {
      return this.users.length - this.usersToShow.length
    },
    isMultipleUsers() {
      return Array.isArray(this.message.userId)
    },
    ...getters(),
  },
  methods: {
    async removeUser(id) {
      const { userId } = this.message

      const userIdsFiltered = userId
        .filter(uId => uId !== id)

      this.$emit('update:message', { ...this.message, userId: userIdsFiltered })

      if (!userIdsFiltered.length) {
        return this.$router.go(-1)
      }

      await this.$router.replace({
        name: 'AdminMessageAddMultiple',
        query: { userIds: userId.join(',') },
      })
    },
  },
}
</script>

<style lang="scss">
.FormMessage {
  .tagError {
    border: 1px solid $danger;
    color: $danger;
  }
}
</style>
